// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api.restaurantcottas.de/api/', // 'http://localhost:8000/api/', //
  BASE_URL: 'https://api.restaurantcottas.de', // 'http://localhost:8000',//
  DEFAULT_LANG: 'de',
  APP_VERSION: '0.1', // change app version to force clear cache
  GOOGLE_COUNTRIES: ["de"], // for google address
  GOOGLE_LOGIN_WEB_CLIENT_ID: '852512128995-4m7nsaus4una61j1ug201s4oss79kp9u.apps.googleusercontent.com',
  FACEBOOK_LOGIN_APP_ID: '388384023323850',
  // theme configuration
  DEFAULT_PRIMARY_COLOR: '#b32c18',
  DEFAULT_PRIMARY_TEXT_COLOR: '#b32c18'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
